import PropTypes from "prop-types";
import React, { useState, useRef, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import BirthdayFields from "./BirthdayFields";
import {
  withValidation,
  FormField,
  Icon,
  NewCheckbox
} from "../../shared-components";
import { useFeatureFlags, useTrans } from "../../hooks";
import PasswordFields from "../../shared-components/PasswordFields";
import { Button } from "@wattpad/web-ui-library";
import classNames from "classnames";

const EmailSignup = ({ formType, nextUrl = "/home", toggleFormView }) => {
  const { trans } = useTrans();

  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isBirthdayValid, setIsBirthdayValid] = useState(false);
  const [isPolicyConsentValid, setIsPolicyConsentValid] = useState(null);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [usernameValue, setUsernameValue] = useState();
  const [isInvalidated, setIsInvalidated] = useState(false);

  const featureFlags = useFeatureFlags();

  const usernameInput = useRef();
  const emailInput = useRef();
  const passwordInput = useRef();
  const confirmPasswordInput = useRef();
  const birthdayInput = useRef();
  const pronounInput = useRef();

  const OLD_FIELD_ORDER = [
    "username",
    "email",
    "password",
    "birthday",
    "policy"
  ];
  const FIELD_ORDER = [
    "email",
    "username",
    "birthday",
    "pronouns",
    "password",
    "email-opt-in",
    "policy"
  ];

  const isValidated =
    isPasswordValid &&
    isEmailValid &&
    isUsernameValid &&
    isBirthdayValid &&
    isPolicyConsentValid;

  const ValidatedFormField = useMemo(() => withValidation(FormField), []);

  // In order for the model to work properly in React needs to be hold as a reference
  const validationModel = useRef(
    window.app?.models && new window.app.models.Authsignup()
  );

  const validateSignupForm = (isValid, key) => {
    if (key.toLowerCase().includes("username")) {
      setIsUsernameValid(isValid);
    } else if (key.toLowerCase().includes("email")) {
      setIsEmailValid(isValid);
    } else if (key.toLowerCase().includes("password")) {
      setIsPasswordValid(isValid);
    } else if (key.toLowerCase().includes("birthday")) {
      setIsBirthdayValid(isValid);
    }
  };

  const actionURL = `/signup?nextUrl=${nextUrl}`;
  const signupFrom = `new_landing_signup`;
  const policy_agreement_old = trans("I have read and agree to Wattpad's <a href='/terms'>Terms of Service</a> and <a href='/privacy'>Privacy Policy</a>"); // prettier-ignore
  const policy_agreement = trans("Yes, I have read and agree to Wattpad's <a href='/terms'>Terms of Service</a> and <a href='/privacy'>Privacy Policy</a>."); // prettier-ignore
  const isFormValid = () => {
    if (!isPasswordValid) {
      featureFlags.NEW_ONBOARDING_1 && passwordInput.current.focus();
      return false;
    } else if (!isEmailValid) {
      featureFlags.NEW_ONBOARDING_1 && emailInput.current.focus();
      return false;
    } else if (!isUsernameValid) {
      featureFlags.NEW_ONBOARDING_1 && usernameInput.current.focus();
      return false;
    } else if (!isBirthdayValid) {
      featureFlags.NEW_ONBOARDING_1 && birthdayInput.current.focus();
      return false;
    } else if (!isPolicyConsentValid) {
      return false;
    }
    return true;
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    if (!isFormValid()) {
      if (featureFlags.NEW_ONBOARDING_1) {
        // Since validation is isolated in the ValidatedFormField component, we need onblur handler
        // to trigger in order to validate and show error messages for all the required fields
        passwordInput.current.focus();
        passwordInput.current.blur();

        confirmPasswordInput.current.focus();
        confirmPasswordInput.current.blur();

        birthdayInput.current.focus();
        birthdayInput.current.blur();

        usernameInput.current.focus();
        usernameInput.current.blur();

        emailInput.current.blur();
        emailInput.current.focus();

        // If policy consent is null, we coerce into boolean value to show error message
        setIsPolicyConsentValid(!!isPolicyConsentValid);
        setIsInvalidated(true);
      }
      return;
    }

    $.ajax({
      type: "post",
      url: actionURL,
      data: $("#signupForm").serialize()
    })
      .done(res => {
        window.location.replace(res.redirectUrl).reload();
      })
      .fail(err => {
        const message =
          err.responseJSON?.msg ||
          trans("Something went wrong. Please try again");
        wattpad.utils.showToast(message, { type: "dismissable" });
      });
  };

  const renderLabelWithHint = (label, ariaLabel, tooltip) => (
    <div className="field-label">
      <span className="field-label-text">{label}</span>
      <div aria-label={ariaLabel} data-tip={tooltip}>
        <Icon
          height="16"
          iconName="fa-info"
          color="wp-neutral-1"
          className="tooltip-icon"
        />
      </div>
    </div>
  );

  const getFormField = fieldname => {
    switch (fieldname) {
      case "username":
        return (
          <React.Fragment key="username">
            {renderLabelWithHint(
              trans("Username"),
              "username hint",
              trans(
                "You do not have to use your real name. You can choose to use another name to protect your privacy."
              )
            )}
            <ValidatedFormField
              name="username"
              form="signup"
              inputType="text"
              title={trans("Enter username")}
              inputRef={usernameInput}
              validationModel={validationModel.current}
              showLabel={true}
              onValid={key => validateSignupForm(true, key)}
              onInvalid={key => validateSignupForm(false, key)}
              onBlur={() => setUsernameValue(usernameInput.current.value)}
            />
          </React.Fragment>
        );
      case "email":
        return (
          <ValidatedFormField
            name="email"
            label={trans("E-mail")}
            form="signup"
            inputType="text"
            title={trans("Enter E-mail")}
            validationModel={validationModel.current}
            inputRef={emailInput}
            showLabel={true}
            onValid={key => validateSignupForm(true, key)}
            onInvalid={key => validateSignupForm(false, key)}
            key="email"
          />
        );
      case "password":
        return (
          <PasswordFields
            username={usernameValue}
            validatePassword={setIsPasswordValid}
            key="password-field"
            passwordRef={passwordInput}
            confirmPasswordRef={confirmPasswordInput}
            featureFlags={featureFlags.NEW_ONBOARDING_1}
          />
        );
      case "birthday":
        return (
          <React.Fragment key="birthday-field">
            {renderLabelWithHint(
              trans("Birthday"),
              "why we ask for your birthday",
              trans(
                "You need to enter the date you were born. This information will only be visible to you and Wattpad’s Support teams."
              )
            )}
            <div className="birthday-fields">
              <BirthdayFields
                inputRef={birthdayInput}
                validationModel={validationModel.current}
                onValid={() => validateSignupForm(true, "birthday")}
                onInvalid={() => validateSignupForm(false, "birthday")}
              />
            </div>
          </React.Fragment>
        );
      case "policy":
        return (
          <React.Fragment key="policy-agreement">
            <div
              className={classNames("email-policy-agreement-container", {
                ["old-padding"]: !featureFlags.NEW_ONBOARDING_1
              })}
            >
              <NewCheckbox
                contentId="policy-consent"
                required
                checked={!!isPolicyConsentValid}
                onChange={() => setIsPolicyConsentValid(!isPolicyConsentValid)}
                color="ds-base-2-60"
                onBlur={() => {
                  setIsPolicyConsentValid(!!isPolicyConsentValid);
                }}
                invalid={isPolicyConsentValid}
              >
                <span
                  className={classNames({
                    "checkbox-title": featureFlags.NEW_ONBOARDING_1,
                    "policy-agreement": !featureFlags.NEW_ONBOARDING_1
                  })}
                  dangerouslySetInnerHTML={{
                    __html: featureFlags.NEW_ONBOARDING_1
                      ? policy_agreement
                      : policy_agreement_old
                  }}
                />
                {featureFlags.NEW_ONBOARDING_1 &&
                  isPolicyConsentValid === false && (
                    <span
                      className={classNames(
                        "checkbox-subtitle",
                        "checkbox-error"
                      )}
                    >
                      {trans("Checking this box is required.")}
                    </span>
                  )}
              </NewCheckbox>
            </div>
          </React.Fragment>
        );
      case "email-opt-in":
        return (
          <div
            className={classNames("email-policy-agreement-container", {
              ["old-padding"]: !featureFlags.NEW_ONBOARDING_1
            })}
            key="email-opt-in"
          >
            <NewCheckbox
              contentId="marketing"
              name="marketing"
              id="marketing"
              checked={isEmailChecked}
              value={isEmailChecked}
              onChange={() => setIsEmailChecked(!isEmailChecked)}
              color="ds-base-2-60"
            >
              <span className="checkbox-title">
                {trans(
                  "Yes, I'd like to receive marketing emails from Wattpad. (optional)"
                )}
              </span>
              <span className="checkbox-subtitle">
                {trans(
                  "Get story recommendations, announcements, offers, and more via email. Unsubscribe anytime."
                )}
              </span>
            </NewCheckbox>
          </div>
        );
      case "pronouns":
        return (
          <React.Fragment key="pronouns">
            {renderLabelWithHint(
              trans("Pronouns (optional)"),
              trans("Why we ask for your pronouns"),
              trans(
                "Your pronouns are only visible to you and Wattpad's support team. Update them anytime in your profile."
              )
            )}
            <FormField
              inputType="pronouns"
              id="pronouns"
              name="pronouns"
              defaultValue=""
              aria-label="Select Pronoun"
              ref={pronounInput}
            />
          </React.Fragment>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <form
        id="signupForm"
        action={actionURL}
        method="POST"
        onSubmit={handleFormSubmit}
        noValidate
      >
        <ReactTooltip className="tool-tip" effect="solid" />
        <input type="hidden" name="signup-from" value={signupFrom} />
        <input type="hidden" name="form-type" value={formType || ""} />

        <div className="input-group-vertical" key="form-fields">
          {featureFlags.NEW_ONBOARDING_1
            ? FIELD_ORDER.map(fieldName => getFormField(fieldName))
            : OLD_FIELD_ORDER.map(fieldName => getFormField(fieldName))}
        </div>
        <Button
          fullWidth={true}
          label={trans("Sign up with email")}
          ariaLabel={trans("Sign up with email")}
          disabled={!featureFlags.NEW_ONBOARDING_1 && !isValidated}
        />
        {featureFlags.NEW_ONBOARDING_1 &&
          isInvalidated &&
          !isValidated && (
            <div
              aria-live="assertive"
              className={classNames("error-msg", "submit-error-msg")}
              role="alert"
            >
              {trans("Please fix any errors to continue.")}
            </div>
          )}
      </form>
      {!featureFlags.NEW_ONBOARDING_1 && (
        <div className="back-link-container">
          <button className="back-link" onClick={toggleFormView}>
            <span className="fa fa-left fa-wp-neutral-1 back-icon" />
            {trans("Back to all signup options")}
          </button>
        </div>
      )}
    </>
  );
};

EmailSignup.propTypes = {
  formType: PropTypes.string,
  nextUrl: PropTypes.string,
  toggleFormView: PropTypes.func.isRequired
};

export default EmailSignup;
