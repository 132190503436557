import { MODAL_TYPE } from "../../../../components/shared-components/modals/constants";

/*
 * DefaultTransition
 * - used as the transition if none is specified
 * - used as the base class of all other transitions (except for BaseTransition)
 * - empty the container and hides the address bar unless told otherwise via options
 */
(function(window, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "DefaultTransition",
    app.transitions.BaseTransition.extend({
      start: function(fromView, toView, options) {
        options = options || { hasHeader: true, hasFooter: true };
        options.hideAnnouncements = options.hideAnnouncements || false;

        // SSR load
        if (!fromView) {
          if (options.hasHeader) {
            this.setupHeader();
          }
          this.setupFooter();
          this.setupAnnouncements();
        } else {
          // CSR
          this._pageView();
        }

        //Set the page title ( don't need to set it on SSR )
        if (fromView && options.pageTitle && options.pageTitle.length > 0) {
          wattpad.utils.setTitle(options.pageTitle);
        }

        // Handle robots meta tags on CSR between pages allow/disallow crawler indexing
        if (
          fromView &&
          options.noIndex &&
          $("meta[content=noindex]").length === 0
        ) {
          $("head").append("<meta name=robots content=noindex />");
        }
        if (fromView && !options.noIndex) {
          $("meta[name='robots']").remove();
        }

        // empty the container by default
        if (options.emptyContainer !== void 0 && !options.emptyContainer) {
          $("#app-container").empty();
        }

        //walk the DOM for modals; close them before continuing
        if ($("body").hasClass("modal-open")) {
          var self = this;
          $(".modal.in").one("hidden.bs.modal", function() {
            self.changeViews(fromView, toView, options);
          });
          $(".modal.in").modal("hide");
        } else {
          this.changeViews(fromView, toView, options);
        }

        // to handle edge case where user csr's to a new page from a page that did not have a header
        if ($("#header").length === 0 && options.hasHeader) {
          this.setupHeader();
        }

        if (options.hasHeader) {
          this.showHeader(toView);
        } else if (options.hasHeader !== undefined) {
          this.hideHeader();
        }

        if (options.hasFooter) {
          this.showFooter();
        } else if (options.hasFooter !== undefined) {
          this.hideFooter();
        }

        var hideWritersPortalAnnouncement =
          $("#announcements-container .writers-portal-announcement").length &&
          window.location.pathname !== "/myworks";

        $("#header")
          .find("#search-query")
          .val("");

        if (
          options.hideAnnouncements ||
          hideWritersPortalAnnouncement ||
          ($("#announcements-container .wattcon").length &&
            window.location.pathname !== "/myworks")
        ) {
          $("#announcements-container").addClass("hide");
        } else {
          $("#announcements-container").removeClass("hide");
        }
        wattpad.utils.hideAddressBar();

        this.scrollTo(window, fromView);

        const isForcedSignupExemptView = wattpad.utils.forcedSignupHelper.isForcedSignupExemptView(
          toView
        );

        const loggedOutSessionCount = parseInt(
          wattpad.utils.getCookie("fs__exp")
        );
        const isForcedSignupSession = wattpad.utils.forcedSignupHelper.isForcedSignupSession(
          loggedOutSessionCount
        );

        if (
          !wattpad.user &&
          isForcedSignupSession &&
          !isForcedSignupExemptView
        ) {
          // Need to close one-tap if user is navigating to a page with the one-tap
          // modal already visible.
          window?.google?.accounts?.id?.cancel();
          this.showForcedSignupModal();
        }

        var currentUser = wattpad.utils.currentUser();
        // has_accepted_latest_tos could be null, so we check explicitly for false
        if (
          currentUser.get("has_accepted_latest_tos") === false &&
          !currentUser.get("has_seen_latest_tos_notice")
        ) {
          this.showUpdatedPolicyNoticeModal();
        }

        return toView;
      },

      changeViews: function(fromView, toView, options) {
        if (fromView && toView.el === fromView.el) {
          fromView.remove();
          toView.render(options);
        }

        // case: transition A -> B
        else if (fromView) {
          toView.render(options);
          fromView.remove();
        }

        // case: render first page
        else {
          options.isSSR = true;
          toView.render(options);
        }

        this.injectDOM(fromView, toView, "#app-container");

        if (options.viewChangedCallback !== undefined) {
          options.viewChangedCallback();
        }
        this.registerModals();
      },

      setupHeader: function() {
        if ($("#header-container").length === 0) {
          $("#app-container")
            .parent()
            .prepend('<div id="header-container"></div>');
        }

        var headerContainer = $("#header-container");
        var $header = $("#header");

        // append the header to the DOM
        var header = new app.views.Header();
        if ($header.length === 0) {
          var headerContent = header.render().$el;
          headerContainer.html(headerContent);

          // Fixes binding click events to #header when navigating
          // from a page without a header to a page that has one on CSR
          _.delay(function() {
            if (headerContent.hammer && _.isFunction(headerContent.hammer)) {
              headerContent.hammer({ behavior: { userSelect: true } });
            }
          });
        } else {
          header.setElement(headerContainer);
        }
        app.set("header-obj", header);
      },

      showHeader: function(toView) {
        var $header = $("#header"),
          $headerContainer = $("#header-container");

        $header.removeClass("hidden").css("display", "table");
        $headerContainer.removeClass("hidden").css("display", "table");

        app.trigger("navbar:search:enable");
      },

      hideHeader: function() {
        var $header = $("#header"),
          $headerContainer = $("#header-container");

        $header.addClass("hidden");
        // Must hide header container as well because on desktop, it
        // has a height that will push down content even if #header is
        // hidden.
        $headerContainer.addClass("hidden");
      },

      setupFooter: function() {
        var footerContainer = $("#footer-container");
        var $footer = $("#footer");

        // append the header to the DOM
        if ($footer.length === 0) {
          footerContainer.append(new app.views.Footer().render().$el);
        } else {
          new app.views.Footer().setElement(footerContainer.children()[0]);
        }
      },

      showFooter: function() {
        var $footer = $("#footer");
        $footer.show();
      },

      hideFooter: function() {
        var $footer = $("#footer");
        $footer.hide();
      },

      setupAnnouncements: function() {
        // Bind announcements
        if ($("#announcements").children().length > 0) {
          _.each($("#announcements").children(), function(child) {
            var $child = $(child);
            var view;
            if ($child.hasClass("announcement")) {
              if ($child.hasClass("verify-email")) {
                view = new app.views.VerifyEmail();
              } else if ($child.hasClass("email-verified-success")) {
                view = new app.views.EmailVerifiedSuccess();
              } else if ($child.hasClass("survey")) {
                view = new app.views.Survey();
              } else if ($child.hasClass("beta")) {
                view = new app.views.BetaProgram();
              } else if ($child.hasClass("transition-group")) {
                view = new app.views.TransitionGroup();
              } else if ($child.hasClass("spielbergo")) {
                view = new app.views.SpielbergoAnnouncment();
              } else {
                view = new app.views.Announcement();
              }
              view.setElement(child);
              app.set("announcement", view);
            }
          });
        }
      },

      scrollTo: function(window, fromView) {
        if (fromView) {
          if (!fromView.shouldNotScrollToTop) {
            window.scrollTo(0, 0);
          } else {
            fromView.shouldNotScrollToTop = void 0;
          }
        }
      },

      showForcedSignupModal: function() {
        var view = new app.views.SignUpPrompt({
          model: new app.models.Authsignup(),
          formType: "navbar_modal",
          form: "signup",
          forcedSignup: true
        });
        $("#generic-modal .modal-body").html(view.render().$el);
        $("#generic-modal")
          .find(".modal-dialog")
          .addClass("signup-modal-wrapper");
        $("#generic-modal")
          .find(".modal-body")
          .addClass("forced-signup-modal");
        $("#generic-modal").modal({ backdrop: "static", keyboard: false });
        $("#generic-modal").one("shown.bs.modal", function() {
          $(this)
            .find("[autofocus]")
            .focus();
        });
      },

      showUpdatedPolicyNoticeModal: function() {
        var body =
          "We’re updating our Terms of Service and Privacy Policy, effective May 25th, 2018." +
          " By continuing to use Wattpad after May 25th, you’re agreeing to these new " +
          "<a href='/terms' target='_blank'>Terms</a> and <a href='/privacy' target='_blank'>Privacy Policy</a>.";

        var updatedPolicyNotice = new app.views.UpdatedPolicyNotice({
          model: wattpad.utils.currentUser(),
          title: "Updating our Terms",
          body: body
        });

        updatedPolicyNotice.showModal();
      },

      registerModals: function() {
        const bootstrapModals = document.getElementsByClassName("modal");
        for (let i = 0; i < bootstrapModals.length; i++) {
          const modal = bootstrapModals.item(i);
          if (!modal.id) return;

          window.wattpad.utils.registerModal(modal.id, MODAL_TYPE.BS_MODAL, {
            isOpen: () => $(`#${modal.id}`).hasClass("in"),
            show: () => {
              $(`#${modal.id}`).modal("show");
            },
            hide: () => {
              $(`#${modal.id}`).modal("hide");
            }
          });
        }
      }
    })
  );
})(window, Monaco, wattpad, window.app);
