(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "OauthAuthentication",
    app.views.DummyReactView.extend({
      component: "RequestAccountDetails",
      componentId: "request-account-details",

      constructor: function(opts) {
        this.componentData = {
          ...opts
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };
        window.te.push("event", "landing", null, null, "signup", {
          platform: "google"
        });
        app.views.DummyReactView.call(this, options);
      }
    })
  );
})(window, _, wattpad, window.app);
