var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"hidden\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "/home";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = 
  "/";
  stack1 = ((helper = (helper = helpers.nextUrl || (depth0 != null ? depth0.nextUrl : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"nextUrl","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.nextUrl) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(container,depth0,helpers,partials,data) {
    return "?nextUrl="
    + container.escapeExpression(container.lambda(depth0, depth0));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"WriteTooltip",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div id=\"go-premium-button\">\n   "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"TryPremium","header",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div id=\"profile-dropdown\" class=\"button-group dropdown\">\n    \n \n    <button data-toggle=\"dropdown\" role=\"button\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"User",{"name":"trans","hash":{},"data":data}))
    + "\">\n      <div class=\"avatar avatar-sm\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.inbox : stack1)) != null ? stack1.unread : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "         "
    + alias3((helpers.connectAvatar || (depth0 && depth0.connectAvatar) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.avatar : stack1),((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.username : stack1),25,{"name":"connectAvatar","hash":{},"data":data}))
    + "\n      </div>\n      <span class=\"username hidden-xs "
    + ((stack1 = helpers.unless.call(alias1,(helpers.getCurrentUserAttr || (depth0 && depth0.getCurrentUserAttr) || alias2).call(alias1,"isPremium",{"name":"getCurrentUserAttr","hash":{},"data":data}),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        "
    + alias3((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.username : stack1),13,{"name":"simpleShorten","hash":{},"data":data}))
    + "\n      </span>\n      <span class=\"caret\"></span>\n    </button>\n\n"
    + ((stack1 = container.invokePartial(partials["desktop.components.header.partials.profile_menu"],depth0,{"name":"desktop.components.header.partials.profile_menu","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"notification-badge\"></span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.notifications : stack1)) != null ? stack1.unread : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"notification-badge\"></span>\n        ";
},"18":function(container,depth0,helpers,partials,data) {
    return " hidden-sm hidden-md ";
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div id=\"login-menu\" role=\"menu\">\n    <a id=\"header-item-login\" rel=\"nofollow\" href=\"/login?nexturl="
    + alias3((helpers.urlEncode || (depth0 && depth0.urlEncode) || alias2).call(alias1,(depth0 != null ? depth0.currentUrl : depth0),{"name":"urlEncode","hash":{},"data":data}))
    + "\" data-form=\"login\" role=\"menuitem\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Log in",{"name":"trans","hash":{},"data":data}))
    + "</a>\n    <a id=\"header-item-signup\" rel=\"nofollow\" href=\"/?nexturl="
    + alias3((helpers.urlEncode || (depth0 && depth0.urlEncode) || alias2).call(alias1,(depth0 != null ? depth0.currentUrl : depth0),{"name":"urlEncode","hash":{},"data":data}))
    + "\" data-form=\"signup\" role=\"menuitem\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Sign Up",{"name":"trans","hash":{},"data":data}))
    + "</a>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, alias5=container.escapeExpression, buffer = 
  "<div role=\"banner\" id=\"header\"";
  stack1 = ((helper = (helper = helpers.hideHeader || (depth0 != null ? depth0.hideHeader : depth0)) != null ? helper : alias2),(options={"name":"hideHeader","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.hideHeader) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n<nav>\n  <ul class=\"list-inline\">\n    <li id='header-item-logo'>\n      <a href=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["current-user"] : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\" title=\"Wattpad Home\" aria-label=\""
    + alias5((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Home",{"name":"trans","hash":{},"data":data}))
    + "\">\n      &nbsp;\n      </a>\n   </li>\n\n    <li id=\"discover-dropdown\" class=\"button-group dropdown\">\n      <button data-toggle=\"dropdown\">"
    + alias5((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Browse",{"name":"trans","hash":{},"data":data}))
    + " <span class=\"caret\"></span></button>\n"
    + ((stack1 = container.invokePartial(partials["desktop.browse.browse_topics"],depth0,{"name":"desktop.browse.browse_topics","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </li>\n\n    <li id=\"community-dropdown\" class=\"button-group dropdown hidden-xs hidden-sm\">\n      <button data-toggle=\"dropdown\">"
    + alias5((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Community",{"name":"trans","hash":{},"data":data}))
    + " <span class=\"caret\"></span></button>\n"
    + ((stack1 = container.invokePartial(partials["core.header.community_menu"],depth0,{"name":"core.header.community_menu","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </li>\n  </ul>\n</nav>\n\n<div id=\"header-item-search\" aria-label=\""
    + alias5((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Search",{"name":"trans","hash":{},"data":data}))
    + "\">\n  <div>"
    + ((stack1 = container.invokePartial(partials["core.header.search_field"],depth0,{"name":"core.header.search_field","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n</div>\n\n<nav>\n  <ul class=\"list-inline\">\n    <li class=\"button-group dropdown hidden-xs hidden-sm\">\n      <button class=\"write-button\" data-toggle=\"dropdown\">"
    + alias5((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Write",{"name":"trans","hash":{},"data":data}))
    + " <span class=\"caret\"></span></button>\n"
    + ((stack1 = container.invokePartial(partials["desktop.components.header.partials.create_menu"],depth0,{"name":"desktop.components.header.partials.create_menu","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = (helpers.isCookieSet || (depth0 && depth0.isCookieSet) || alias2).call(alias1,"seen-new-onboarding",{"name":"isCookieSet","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </li>\n  </ul>\n</nav>\n\n\n"
    + ((stack1 = helpers.unless.call(alias1,(helpers.getCurrentUserAttr || (depth0 && depth0.getCurrentUserAttr) || alias2).call(alias1,"isPremium",{"name":"getCurrentUserAttr","hash":{},"data":data}),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["current-user"] : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
  stack1 = ((helper = (helper = helpers["current-user"] || (depth0 != null ? depth0["current-user"] : depth0)) != null ? helper : alias2),(options={"name":"current-user","hash":{},"fn":container.noop,"inverse":container.program(20, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers["current-user"]) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true});