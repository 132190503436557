(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "FacebookAuthentication",
    app.views.DummyReactView.extend({
      component: "OnboardingDetails",
      componentId: "onboarding-details",

      constructor: function(opts) {
        this.componentData = {
          ...opts
        };

        const options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };
        window.te.push("event", "landing", null, null, "signup", {
          platform: "facebook"
        });

        app.views.DummyReactView.call(this, options);
      }
    })
  );
})(window, _, wattpad, window.app);
