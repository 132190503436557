(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * Purpose: canonical way of prompting the signup modal
   * Options:
   *    - this.title for the title of the signup prompt
   *    - this.subtitle for the subtitle of the signup prompt
   *
   * @mixin AuthPromptManagement
   */
  app.add(
    "AuthPromptManagement",
    Monaco.Mixin.create({
      title: function() {
        return wattpad.utils.trans(
          "Sign up to vote or add stories to your library and receive updates"
        );
      },

      subtitle: function() {
        return;
      },

      onAuthPrompt: function(evt, noop) {
        // Signed in users should be redirected back to the page they were on
        if (wattpad.utils.currentUser().authenticated()) {
          var nextUrl = (this.options && this.options.nextUrl) || "";
          wattpad.utils.redirectToServer(decodeURIComponent(nextUrl));
        } else if ($("#generic-modal").length === 0) {
          app.router.navigate(
            "/login?nexturl=" + wattpad.utils.getNextUrl(window.location.href),
            { trigger: true, replace: true }
          );
        } else {
          var view = new app.views.SignUpPrompt({
            model: new app.models.Authsignup(),
            title: _.result(this, "title"),
            subtitle: _.result(this, "subtitle"),
            formType: "modal",
            form: evt.currentTarget.getAttribute("data-form")
          });
          $("#generic-modal .modal-content").addClass("auth-modal");
          $("#generic-modal .modal-body").html(view.render().$el);
          $("#generic-modal").modal("show");
          $("#generic-modal").one("shown.bs.modal", function() {
            $(this)
              .find("[autofocus]")
              .focus();
          });
        }
        noop(evt);
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
